@import '../libs/bootstrap/variables';
@import '../functions';
@import '../settings';
@import 'bootstrap/scss/mixins/breakpoints';

#mySidenav {
    top: 60px;
}
/* The side navigation menu */
.sidenav {
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    height: calc(100% - 60px);
    width: 0; /* 0 width - change this with JavaScript */
    top: 0; /* Stay at the top */
    right: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: auto;
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    padding-top: 35px;
    border-radius: 4px;
    background-color: rgba(white, 0.95);
    box-shadow: 0 10px 10px rgba(black, 0.2);
}

.sidenav-inner {
    width: 280px;
}

/* The navigation menu links */
.sidenav a {
    padding: rem-calc(8 8 8 32);
    text-decoration: none;
    color: $dark-purple;
    display: block;
    font-size: 15px;
    font-weight: 500;
    transition: 0.4s;

    @include media-breakpoint-up(md) {
        font-size: $global-font-size + rem-calc(4);
    }

    @include media-breakpoint-only(sm) {
        padding: 0;
        padding-left: 2rem;
        padding-bottom: 7px;
    }
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
    color: $cc-green;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    display: block;
    font-size: 36px;
    float: left;
    width: 100%;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left 0.5s;
    padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}
